<script setup lang="ts">
import { PropType } from 'vue'
import { Category, ContentType, Tag } from '@/models/content'
import ProtectedLink from '@/components/common/ProtectedLink.vue'
import BaseLink from '@/components/common/BaseLink.vue'
import IconPlay from '@/assets/icons/play.svg'

const props = defineProps({
  contentType: {
    type: String as PropType<ContentType>,
    default: 'article',
    validator(value: string) {
      return ['article', 'chart', 'video'].includes(value)
    },
  },
  slug: {
    type: [String, Number],
    required: true,
  },
  publishedDate: {
    type: String,
    required: true,
  },
  duration: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  excerpt: {
    type: String,
    required: true,
  },
  pills: {
    type: Array as PropType<Tag[] | Category[]>,
    required: true,
  },
  titleClasses: {
    type: String,
    default: 'text-xl md:text-3xl uppercase',
  },
  isProtected: {
    type: Boolean,
    default: false,
  },
})

const isMobile = useMobile()

const formattedDuration = useFormattedDuration(
  props.contentType,
  props.duration,
)

const isVideo = computed(() => props.contentType === 'video')
const isArticle = computed(() => props.contentType === 'article')

const href = computed(() => useContentHref(props.contentType, props.slug))
</script>

<template>
  <article :class="{ 'bg-s-100 py-6 md:bg-opacity-0 md:py-0': isVideo }">
    <p class="text-xs font-semibold text-s-500 md:text-white">
      {{ publishedDate }} · {{ formattedDuration }}
    </p>

    <component
      :is="isProtected ? ProtectedLink : BaseLink"
      :data-testid="isVideo ? 'video-card-title' : 'title'"
      :href="href"
      variant="unstyled"
    >
      <h1
        class="mt-2 max-w-[980px] font-bold line-clamp-3 md:font-inter"
        :class="titleClasses"
      >
        {{ title }}
      </h1>
    </component>

    <div class="mt-7 mb-5 hidden h-line-4 w-16 bg-p-500 md:block" />

    <div
      class="mt-3 mb-4 max-w-[780px] md:mt-0"
      :class="{ 'hidden md:block': isArticle }"
    >
      <div
        class="custom-line-clamp-lg text-base md:font-semibold"
        v-html="excerpt"
      />
    </div>

    <div class="mt-4 flex gap-2" :class="{ 'hidden md:flex': isArticle }">
      <LinkedPill
        v-for="pill in pills"
        :key="pill.id"
        :type="pill.__typename"
        :slug="pill.slug"
        :variant="isMobile ? 'black' : 'white'"
      >
        {{ pill.name }}
      </LinkedPill>
    </div>

    <div v-if="isVideo" class="mt-11 hidden md:flex">
      <BaseLink :href="href"> <IconPlay class="h-5 w-5" /> Watch Now </BaseLink>
    </div>
  </article>
</template>
